import Vue from 'vue'
import App from './App.vue'
import router from './router'

import "./plugins/fontawesome";

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-KKR8FCH84N" }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
